//=======================
// Developer Variable
//=======================

// scss-docs-start social-colors-map
$social-colors: (
    "android": #7ac157,
    "apple": #b8b8b8,
    "behance": #1869ff,
    "codepen": #000000,
    "dribbble": #ea4c8a,
    "dropbox": #007ee5,
    "evernote": #78d525,
    "facebook": #4867aa,
    "github": #313131,
    "googleDrive": #1da462,
    "googleEarth": #4285f4,
    "googleGlass": #ea4335,
    "googleMaps": #5083c3,
    "googlePlay": #01b9fd,
    "googlePlus": #dd5144,
    "google": #4285f4,
    "instagram": #b23a94,
    "css3": #0277bd,
    "html5": #e44d26,
    "javascript": #f9dc3d,
    "python": #0c9dbf,
    "lastfm": #e31b23,
    "linkedin": #007bb6,
    "paypal": #002f86,
    "pinterest": #bd081b,
    "pocket": #ef3e56,
    "polymer": #f87292,
    "rss": #f99c3a,
    "share": #2c9cff,
    "stackoverflow": #f38024,
    "steam": #15497b,
    "twitter": #1da1f2,
    "vk": #5181b8,
    "wikipedia": #e9e9e9,
    "windows": #0078d6,
    "s500px": #000000,
    "s8tracks": #122d4b,
    "amazon": #f79b34,
    "blogger": #f06a35,
    "delicious": #0000fe,
    "disqus": #2e9efe,
    "flattr": #7ab831,
    "flickr": #fe0084,
    "odnoklassniki": #f58220,
    "outlook": #0072c6,
    "playstation": #07418e,
    "reddit": #ff4500,
    "skype": #00a9f0,
    "slideshare": #0077b5,
    "soundcloud": #fe4900,
    "tumblr": #36465d,
    "twitch": #6441a4,
    "vimeo": #1ab7ea,
    "whatsapp": #189d0e,
    "xbox": #107c0f,
    "yahoo": #4101af,
    "youtube": #fe0000,
);
// scss-docs-end theme-colors-map

// Responsive Variables
$extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";

//===============================
// Bootstrap Variables Overright
//===============================

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// fusv-disable
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
) !default;
// fusv-enable

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #da2929 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// scss-docs-start colors-map
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

$primary: #89715b !default;
$secondary: $gray-600 !default;
$success: #f58344 !default;
$info: $cyan !default;
$warning: #feae01 !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: #090909 !default;

// scss-docs-start theme-colors-map
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
) !default;
// scss-docs-end theme-colors-map

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-negative-margins: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.3125,
    2: $spacer * 0.625,
    3: $spacer * 0.9375,
    4: $spacer * 1.25,
    5: $spacer * 1.625,
    6: $spacer * 1.875,
    7: $spacer * 2.1875,
    8: $spacer * 2.5,
    9: $spacer * 2.8125,
    10: $spacer * 3.125,
);
$body-color: rgba(255, 255, 255, 0.75);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
);
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
    xxl: 1201px,
);
// scss-docs-end container-max-widths

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 30px;
//
$gutters: $spacers;

// Transition
$transition-base: all 0.3s ease 0s;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-enable value-keyword-case
$font-family-base: "Poppins", sans-serif;
$custom-font-family: "Inter", sans-serif;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-base: 0.875rem;
//
//
$line-height-base: 1.5;
//
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
//
$headings-font-family: "Zilla Slab", serif;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: null;
