/*--
/*  4.8 - Pricing CSS
/*----------------------------------------*/

// PRicing Banner Section 
.pricing-banner-section{
    height: 820px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 3;

    @media #{$extraBig-device}{
        height: 965px;
    }

    @media #{$large-mobile}{
        height: 500px;
    }
    @media #{$small-mobile}{
        height: 420px;
    }

    &::before{
        position: absolute;
        content: '';
        background: $black;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.45;
    }
}

// Pricing Banner Content 
.pricing-banner-content{
    padding-top: 247px;

    @media #{$large-mobile, $tablet-device}{
        padding-top: 187px;
    }
    @media #{$small-mobile}{
        padding-top: 147px;
    }

    & .title{
        font-size: 60px;
        font-weight: 700;
        color: $white;

        & span{
            color: $primary;
        }

        @media #{$laptop-device}{
            font-size: 50px;
        }
        @media #{$desktop-device}{
            font-size: 42px;
        }
        @media #{$tablet-device, $large-mobile}{
            font-size: 38px;
        }
        @media #{$small-mobile}{
            font-size: 24px;

            & br{
                display: none;
            }
        }        
    }

    & p{
        margin-bottom: 0;
        margin-top: 45px;
    }
}

// Single Pricing Box 
.pricing-table-wrapper{
    margin-top: -320px;
    position: relative;
    z-index: 5;

    @media #{$large-mobile}{
        margin-top: 0;
        padding-top: 30px;
    }

    & .row{
        & .col{
            & .single-pricing-box{
                margin-left: -1px;
            }
        }
    }
}

// Single Pricing Box 
.single-pricing-box{
    background-color: $dark;
    border: 1px solid $primary;
    text-align: center;
    position: relative;    

    & .pricing-iner{
        padding: 100px 60px 60px;

        @media #{$desktop-device}{
            padding: 80px 40px 40px;
        }
        @media #{$tablet-device, $small-mobile}{
            padding: 70px 30px 30px;
        }
    }

    @media #{$large-mobile}{
        margin-top: 30px;
    }
    
    & .popular{
        position: absolute;
        left: 50%;
        top: 0px;
        color: $primary;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 3px;
        padding: 7px 30px;
        text-transform: uppercase;
        transform: translateX(-50%);
        border-radius: 0px 0px 35px 35px;
        background-color: #33281e;

        @media #{$small-mobile}{
            font-size: 14px;
            padding: 5px 30px;
        }
    }

    & .icon-box{
        position: relative;
        color: $primary;
        font-size: 100px;  
        line-height: 1;

        @media #{$desktop-device}{
            font-size: 80px;
        }
        @media #{$tablet-device, $small-mobile}{
            font-size: 60px;
        }
    }
    & .title{
        font-size: 18px;
        font-weight: 400;
        color: $white;
        text-transform: uppercase;
        margin-top: 62px;
        letter-spacing: 2px;

        @media #{$tablet-device, $small-mobile}{
            margin-top: 42px;
        }
    }
    & .price{
        font-size: 56px;
        color: $white;
        font-family: $custom-font-family;
        font-weight: 700;
        line-height: 1;
        margin-top: 20px;

        @media #{$desktop-device}{
            font-size: 42px;
        }
        @media #{$tablet-device, $small-mobile}{
            font-size: 34px;
        }

        & sup{
            font-size: 20px;
            vertical-align: top;
            top: 15px;
            left: -5px;

            @media #{$tablet-device, $small-mobile}{
                font-size: 15px;
                top: 10px;
            }
        }
        & span{
            font-size: 20px;
            font-weight: 400;

            @media #{$tablet-device, $small-mobile}{
                font-size: 15px;
            }
        }
    }
    & .price-list{
        position: relative;
        margin-bottom: 25px;
        margin-top: 45px;

        & li{
            position: relative;
            font-size: 14px;
            padding: 22px 0px;
            text-transform: uppercase;
            border-top: 1px solid #89715b;

            @media #{$tablet-device, $small-mobile}{
                & br{
                    display: none;
                }
            }
        }
        
    }
    & .btn{
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: 50px;
        line-height: 46px;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $custom-font-family;
    }
}


