/*--
/*  4.7 - Skills CSS
/*----------------------------------------*/


// Skills Wrapper 
.skills-wrapper{
    border-top: 1px solid rgba($white, 0.15);
}


// Skills Bar Wrapper 
.skill-bar-wrapper{
    margin-top: -5px;

    @media #{$tablet-device, $large-mobile}{
        padding-top: 30px;
    }

    & .single-skills-bar{
        & + .single-skills-bar{
            padding-top: 18px;
        }
    }
}


// Single Skills Bar 
.single-skills-bar{    

    & .title{
        font-size: 14px;
        font-family: $custom-font-family;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: inline-block;
        color: $white;
    }
    
    & .skill-bar{
        position: relative;
        width: 100%;
        height: 14px;
        
        & .bar-inner{
            position: relative;
            width: 100%;
            height: 14px;
            background: $white;
            .progress{
                height: 14px;
                border-radius: 0;
                overflow: visible;
            }
            & .bar{
                position: absolute;
                left: 0px;
                top: 0px;
                height: 14px;
                background-color: $primary;
                transition: all 2s ease-out 0s;
                overflow: visible;

                & .skill-percentage{
                    position: absolute;
                    right: 0px;
                    top: -35px;
                    float: right;
                    font-weight: 400;
                    color: $white;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }
        }
    }
}














